<template>
  <div class="container-center-horizontal">
    <div class="prices-1920 screen">
      <div class="overlap-group6">
        <img class="rectangle-21" :src="rectangle21" alt="Rectangle 21" />
        <div class="rectangle-11"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <div class="flex-row">
        <h1 class="algt-report-price-list plusjakartasans-bold-mine-shaft-48px">{{ algtReportPriceList }}</h1>
        <div class="overlap-group2">
          <img
            class="vector-1"
            src="img/vector-9.svg"
            alt="Vector"
          />
          <div class="fee-pdf heebo-bold-white-24px">
            <a :href="globalData.algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
            {{ feePdf }}
          </a>
        </div>
        </div>
      </div>
      <div class="flex-row-1">
        <div class="flex-col-1">
          <div class="name plusjakartasans-bold-mine-shaft-24px">{{ name1 }}</div>
          <tag :x029="tag1Props.x029" />
          <tag2 :text5="tag21Props.text5" :feeProps="tag21Props.feeProps" />
          <tag2 :text5="tag22Props.text5" :feeProps="tag22Props.feeProps" />
        </div>
        <div class="flex-col">
          <tag :x029="tag2Props.x029" :className="tag2Props.className" />
          <tag2 :text5="tag23Props.text5" :className="tag23Props.className" :feeProps="tag23Props.feeProps" />
          <tag2 :text5="tag24Props.text5" :className="tag24Props.className" :feeProps="tag24Props.feeProps" />
        </div>
      </div>
      <div class="flex-row-2">
        <div class="flex-col-2">
          <p class="colored-stone-identi plusjakartasans-bold-mine-shaft-24px">{{ coloredStoneIdenti }}</p>
          <div class="ruby-sapphire-emeral plusjakartasans-normal-mine-shaft-20px">{{ rubySapphireEmeral }}</div>
          <tag :x029="tag3Props.x029" />
          <tag2 :text5="tag25Props.text5" :feeProps="tag25Props.feeProps" />
          <tag2 :text5="tag26Props.text5" :feeProps="tag26Props.feeProps" />
          <tag2 :text5="tag27Props.text5" :feeProps="tag27Props.feeProps" />
          <tag2 :text5="tag28Props.text5" :feeProps="tag28Props.feeProps" />
          <tag2 :text5="tag29Props.text5" :feeProps="tag29Props.feeProps" />
          <tag2 :text5="tag210Props.text5" :feeProps="tag210Props.feeProps" />
          <tag2 :text5="tag211Props.text5" :feeProps="tag211Props.feeProps" />
        </div>
        <div class="flex-col-3">
          <tag :x029="tag4Props.x029" :className="tag4Props.className" />
          <tag2 :text5="tag212Props.text5" :className="tag212Props.className" :feeProps="tag212Props.feeProps" />
          <tag2 :text5="tag213Props.text5" :className="tag213Props.className" :feeProps="tag213Props.feeProps" />
          <tag2 :text5="tag214Props.text5" :className="tag214Props.className" :feeProps="tag214Props.feeProps" />
          <tag2 :text5="tag215Props.text5" :className="tag215Props.className" :feeProps="tag215Props.feeProps" />
          <tag2 :text5="tag216Props.text5" :className="tag216Props.className" :feeProps="tag216Props.feeProps" />
          <tag2 :text5="tag217Props.text5" :className="tag217Props.className" :feeProps="tag217Props.feeProps" />
        </div>
      </div>
      <div class="flex-row-3">
        <div class="flex-col-4">
          <div class="other-colored-stones-loose plusjakartasans-bold-mine-shaft-24px">
            {{ otherColoredStonesLoose }}
          </div>
          <tag :x029="tag5Props.x029" />
          <tag2 :text5="tag218Props.text5" :feeProps="tag218Props.feeProps" />
          <tag2 :text5="tag219Props.text5" :feeProps="tag219Props.feeProps" />
          <tag2 :text5="tag220Props.text5" :feeProps="tag220Props.feeProps" />
          <tag2 :text5="tag221Props.text5" :feeProps="tag221Props.feeProps" />
          <tag2 :text5="tag222Props.text5" :feeProps="tag222Props.feeProps" />
        </div>
        <div class="flex-col-5">
          <tag :x029="tag6Props.x029" :className="tag6Props.className" />
          <tag2 :text5="tag223Props.text5" :className="tag223Props.className" :feeProps="tag223Props.feeProps" />
          <tag2 :text5="tag224Props.text5" :className="tag224Props.className" :feeProps="tag224Props.feeProps" />
          <tag2 :text5="tag225Props.text5" :className="tag225Props.className" :feeProps="tag225Props.feeProps" />
          <tag2 :text5="tag226Props.text5" :className="tag226Props.className" :feeProps="tag226Props.feeProps" />
        </div>
      </div>
      <div class="flex-row-4">
        <div class="flex-col-6">
          <div class="name-1 plusjakartasans-bold-mine-shaft-24px">{{ name2 }}</div>
          <tag :x029="tag7Props.x029" :className="tag7Props.className" />
          <tag2 :text5="tag227Props.text5" :feeProps="tag227Props.feeProps" />
        </div>
        <div class="flex-col-7">
          <tag :x029="tag8Props.x029" :className="tag8Props.className" />
          <tag2 :text5="tag228Props.text5" :className="tag228Props.className" :feeProps="tag228Props.feeProps" />
        </div>
      </div>
      <div class="overlap-group4">
        <div class="extra-service-for-delivery plusjakartasans-bold-mine-shaft-24px">{{ extraServiceForDelivery }}</div>
        <tag :x029="tag9Props.x029" :className="tag9Props.className" />
        <tag2 :text5="tag229Props.text5" :feeProps="tag229Props.feeProps" />
      </div>
      <div class="flex-row-5">
        <div class="flex-col-8">
          <p class="rough-diamond-identi plusjakartasans-bold-mine-shaft-24px">{{ roughDiamondIdenti }}</p>
          <tag :x029="tag10Props.x029" />
          <tag2 :text5="tag230Props.text5" :className="tag230Props.className" :feeProps="tag230Props.feeProps" />
          <tag2 :text5="tag231Props.text5" :className="tag231Props.className" :feeProps="tag231Props.feeProps" />
        </div>
        <div class="flex-col">
          <tag :x029="tag11Props.x029" :className="tag11Props.className" />
          <tag2 :text5="tag232Props.text5" :className="tag232Props.className" :feeProps="tag232Props.feeProps" />
          <tag2 :text5="tag233Props.text5" :className="tag233Props.className" :feeProps="tag233Props.feeProps" />
        </div>
      </div>
      <div class="flex-row-6">
        <div class="flex-col-9">
          <div class="jewelry-identification-report plusjakartasans-bold-mine-shaft-24px">
            {{ jewelryIdentificationReport }}
          </div>
          <tag :x029="tag12Props.x029" />
          <tag2 :text5="tag234Props.text5" :className="tag234Props.className" :feeProps="tag234Props.feeProps" />
          <tag2 :text5="tag235Props.text5" :className="tag235Props.className" :feeProps="tag235Props.feeProps" />
          <tag2 :text5="tag236Props.text5" :className="tag236Props.className" :feeProps="tag236Props.feeProps" />
        </div>
        <div class="flex-col-10">
          <tag :x029="tag13Props.x029" :className="tag13Props.className" />
          <tag2 :text5="tag237Props.text5" :className="tag237Props.className" :feeProps="tag237Props.feeProps" />
          <tag2 :text5="tag238Props.text5" :className="tag238Props.className" :feeProps="tag238Props.feeProps" />
          <tag2 :text5="tag239Props.text5" :className="tag239Props.className" :feeProps="tag239Props.feeProps" />
        </div>
      </div>
      <div class="flex-row-7">
        <div class="flex-col-11">
          <div class="additional-service plusjakartasans-bold-mine-shaft-24px">{{ additionalService }}</div>
          <tag :x029="tag14Props.x029" />
          <tag2 :text5="tag240Props.text5" :feeProps="tag240Props.feeProps" />
          <tag2 :text5="tag241Props.text5" :feeProps="tag241Props.feeProps" />
          <tag2 :text5="tag242Props.text5" :feeProps="tag242Props.feeProps" />
          <tag2 :text5="tag243Props.text5" :feeProps="tag243Props.feeProps" />
          <tag2 :text5="tag244Props.text5" :feeProps="tag244Props.feeProps" />
          <tag2 :text5="tag245Props.text5" :feeProps="tag245Props.feeProps" />
          <tag2 :text5="tag246Props.text5" :feeProps="tag246Props.feeProps" />
          <tag2 :text5="tag247Props.text5" :feeProps="tag247Props.feeProps" />
          <tag3 :x029="tag31Props.x029" :feeProps="tag31Props.feeProps" />
          <tag2 :text5="tag248Props.text5" :feeProps="tag248Props.feeProps" />
        </div>
        <div class="flex-col-12">
          <tag :x029="tag15Props.x029" :className="tag15Props.className" />
          <tag2 :text5="tag249Props.text5" :className="tag249Props.className" :feeProps="tag249Props.feeProps" />
          <tag2 :text5="tag250Props.text5" :className="tag250Props.className" :feeProps="tag250Props.feeProps" />
          <tag2 :text5="tag251Props.text5" :className="tag251Props.className" :feeProps="tag251Props.feeProps" />
          <tag2 :text5="tag252Props.text5" :className="tag252Props.className" :feeProps="tag252Props.feeProps" />
          <tag2 :text5="tag253Props.text5" :className="tag253Props.className" :feeProps="tag253Props.feeProps" />
          <tag2 :text5="tag254Props.text5" :className="tag254Props.className" :feeProps="tag254Props.feeProps" />
          <tag2 :text5="tag255Props.text5" :className="tag255Props.className" :feeProps="tag255Props.feeProps" />
          <tag2 :text5="tag256Props.text5" :className="tag256Props.className" :feeProps="tag256Props.feeProps" />
          <tag3 :x029="tag32Props.x029" :className="tag32Props.className" :feeProps="tag32Props.feeProps" />
          <tag2 :text5="tag257Props.text5" :className="tag257Props.className" :feeProps="tag257Props.feeProps" />
        </div>
      </div>
      <group46 :algtReportPriceListPdf="globalData.algtReportPriceListPdf"/>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1920";
import Info from "./components/Info";
import Tag from "./price/Tag";
import Tag2 from "./price/Tag2";
import Tag3 from "./price/Tag3";
import Group46 from "./price/Group46";
export default {
  name: "Price1920",
  components: {
    LeftNav,
    Foot,
    Info,
    Tag,
    Tag2,
    Tag3,
    Group46,
  },
  props: [
    "globalData",
    "rectangle21",
    "algtReportPriceList",
    "feePdf",
    "name1",
    "coloredStoneIdenti",
    "rubySapphireEmeral",
    "otherColoredStonesLoose",
    "name2",
    "extraServiceForDelivery",
    "roughDiamondIdenti",
    "jewelryIdentificationReport",
    "additionalService",
    "tag1Props",
    "tag21Props",
    "tag22Props",
    "tag2Props",
    "tag23Props",
    "tag24Props",
    "tag3Props",
    "tag25Props",
    "tag26Props",
    "tag27Props",
    "tag28Props",
    "tag29Props",
    "tag210Props",
    "tag211Props",
    "tag4Props",
    "tag212Props",
    "tag213Props",
    "tag214Props",
    "tag215Props",
    "tag216Props",
    "tag217Props",
    "tag5Props",
    "tag218Props",
    "tag219Props",
    "tag220Props",
    "tag221Props",
    "tag222Props",
    "tag6Props",
    "tag223Props",
    "tag224Props",
    "tag225Props",
    "tag226Props",
    "tag7Props",
    "tag227Props",
    "tag8Props",
    "tag228Props",
    "tag9Props",
    "tag229Props",
    "tag10Props",
    "tag230Props",
    "tag231Props",
    "tag11Props",
    "tag232Props",
    "tag233Props",
    "tag12Props",
    "tag234Props",
    "tag235Props",
    "tag236Props",
    "tag13Props",
    "tag237Props",
    "tag238Props",
    "tag239Props",
    "tag14Props",
    "tag240Props",
    "tag241Props",
    "tag242Props",
    "tag243Props",
    "tag244Props",
    "tag245Props",
    "tag246Props",
    "tag247Props",
    "tag31Props",
    "tag248Props",
    "tag15Props",
    "tag249Props",
    "tag250Props",
    "tag251Props",
    "tag252Props",
    "tag253Props",
    "tag254Props",
    "tag255Props",
    "tag256Props",
    "tag32Props",
    "tag257Props",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.prices-1920
  align-items: center
  background-color: $selago-2
  display: flex
  flex-direction: column
  height: 4369px
  overflow: hidden
  position: relative
  width: 1920px

.overlap-group6
  align-self: flex-start
  height: 400px
  position: relative
  width: 2000px

.rectangle-21
  height: 400px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-11
  background-color: $black-3
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.vector-stroke
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.flex-row
  align-items: flex-start
  display: flex
  gap: 458px
  margin-left: 2.0px
  margin-top: 58px
  min-width: 1402px

.algt-report-price-list
  letter-spacing: 0
  line-height: normal
  margin-top: 1px
  min-height: 60px
  min-width: 579px

.overlap-group2
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  gap: 12px
  height: 64px
  min-width: 363px
  padding: 12px 30px

.vector-1
  height: 24px
  margin-bottom: 4.0px
  width: 18px

.fee-pdf
  align-self: flex-end
  letter-spacing: 0
  line-height: 38.4px
  min-width: 273px
  white-space: nowrap

.flex-row-1
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 257px
  margin-top: 58px
  min-width: 1400px
  padding: 39px 50px

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 178px
  position: relative
  width: 648px

.name,
.colored-stone-identi,
.other-colored-stones-loose,
.name-1,
.extra-service-for-delivery,
.rough-diamond-identi,
.jewelry-identification-report,
.additional-service
  letter-spacing: 0
  line-height: normal
  min-height: 30px

.flex-col
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 2px
  margin-bottom: 1px
  min-height: 127px
  position: relative
  width: 648px

.flex-row-2
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 507px
  margin-top: 30px
  min-width: 1400px
  padding: 39px 50px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 428px
  position: relative
  width: 648px

.ruby-sapphire-emeral
  letter-spacing: 0
  line-height: normal
  margin-top: 10px
  min-height: 25px

.flex-col-3
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  gap: 2px
  margin-top: 42.0px
  min-height: 299px
  position: relative
  width: 648px

.flex-row-3
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 386px
  margin-top: 30px
  min-width: 1400px
  padding: 39px 50px

.flex-col-4
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 307px
  position: relative
  width: 648px

.flex-col-5
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  gap: 2px
  margin-top: 7.0px
  min-height: 213px
  position: relative
  width: 648px

.flex-row-4
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 214px
  margin-top: 30px
  min-width: 1400px
  padding: 39px 50px

.flex-col-6
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 135px
  position: relative
  width: 649px

.flex-col-7
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 2px
  margin-bottom: 1px
  min-height: 84px
  position: relative
  width: 648px

.overlap-group4
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 214px
  padding: 39px 50px
  position: relative
  width: 1400px

.flex-row-5
  align-items: flex-start
  background-color: $white
  display: flex
  height: 257px
  margin-left: 2.0px
  margin-top: 30px
  min-width: 1400px
  padding: 39px 50px

.flex-col-8
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 178px
  position: relative
  width: 650px

.flex-row-6
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 401px
  margin-left: 2.0px
  margin-top: 30px
  min-width: 1400px
  padding: 39px 50px

.flex-col-9
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 322px
  position: relative
  width: 648px

.flex-col-10
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  margin-bottom: 1px
  min-height: 271px
  position: relative
  width: 648px

.flex-row-7
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 2px
  height: 638px
  margin-left: 2.0px
  margin-top: 30px
  min-width: 1400px
  padding: 25px 50px

.flex-col-11
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 573px
  position: relative
  width: 648px

.flex-col-12
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 2px
  margin-bottom: 15px
  min-height: 522px
  position: relative
  width: 648px
</style>
